import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

export const getCategories = state =>
  state.categories && state.categories.items ? state.categories.items : [];

export const getCategoryFacets = state =>
  state.equipment &&
  state.equipment.meta &&
  state.equipment.meta.facets &&
  state.equipment.meta.facets.categories
    ? state.equipment.meta.facets.categories
    : {};

export const getSelectedCategory = state =>
  state.categories ? state.categories.selected : {};

const getSelectedEquipmentAttributes = state =>
  state.categories && state.categories.selected
    ? state.categories.selected.equipmentAttributes
    : [];

const getSelectedVariantAttributes = state =>
  state.categories && state.categories.selected
    ? state.categories.selected.variantAttributes
    : [];

export const getCategoryById = createCachedSelector(
  [getCategories, (state, id) => id],
  (categories, id) => categories.find(cat => String(cat.id) === String(id))
)((categories, id) => `category-${id}`);

export const getPublishedCategories = createSelector(
  [getCategories],
  categories => categories.filter(category => category.state === 'published')
);

export const getCategoriesWithDisabledSelected = createSelector(
  [getCategories, (state, selected) => selected],
  (categories, selected) =>
    categories.map(category => ({
      ...category,
      disabled: selected && String(category.id) === String(selected.id)
    }))
);

export const getCategoryWithPriorityName = createSelector(
  [state => state, (state, category) => category],
  (state, category) => ({
    ...category,
    priorityName:
      !!category.priority && state.locale.categories.priority[category.priority]
  })
);

const createCategoriesHierarchy = (categories = []) => {
  var map = { '-': { children: [], facetsTotal: 0 } };

  if (categories && categories.length) {
    categories
      .sort((a, b) =>
        a.sortOrder > b.sortOrder ? 1 : a.sortOrder < b.sortOrder ? -1 : 0
      )
      .forEach(category => {
        const useFacets = typeof category.facets !== 'undefined';

        var obj = {
          ...category,
          children: map[category.id] ? map[category.id].children : []
        };

        if (useFacets) {
          obj.facets = category.facets || 0;
          obj.facetsTotal = obj.facets + ((map[category.id] && map[category.id].facetsTotal) || 0);
        }

        map[obj.id] = obj;

        var parent = obj.parentCategory || '-';

        if (!map[parent]) {
          map[parent] = { children: [] };

          if (useFacets) {
            map[parent].facetsTotal = 0;
          }
        }

        if (useFacets) {
          var tempParent = parent;

          while (tempParent !== '-') {
            map[tempParent].facetsTotal += obj.facetsTotal;
            tempParent = map[tempParent].parentCategory || '-';
          }
        }

        map[parent].children.push(obj);
      });
  }
  return map['-'].children;
};

export const getCategoriesWithFacets = createSelector(
  [getCategories, getCategoryFacets],
  (categories, facets) =>
    categories.map(c => ({ ...c, facets: facets[c.id] || 0 }))
);

export const getCategoriesHierarchy = createSelector(
  [getCategories],
  createCategoriesHierarchy
);

export const getCategoriesHierarchyWithFacets = createSelector(
  [getCategoriesWithFacets],
  createCategoriesHierarchy
);

export const getPublishedCategoriesHierarchy = createSelector(
  [getPublishedCategories],
  createCategoriesHierarchy
);

export const getPublishedCategoriesHierarchyOnlyIncludingVariantAttributes = createSelector(
  [getPublishedCategories],
  categories =>
    checkInheritedVariantAttributes(createCategoriesHierarchy(categories))
);

const checkInheritedVariantAttributes = categories =>
  categories
    .reduce((acc, category) => {
      if (!!category.variantAttributes && !!category.variantAttributes.length) {
        return [...acc, category];
      } else {
        const children = checkInheritedVariantAttributes(category.children);
        if (children.length) {
          return [
            ...acc,
            {
              ...category,
              children
            }
          ];
        }
      }
      return acc;
    }, [])
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

export const getRelatedCategoriesHierarchy = createSelector(
  [getCategoriesWithDisabledSelected],
  createCategoriesHierarchy
);

const getAttributesFormValues = attrs => {
  return attrs.map(item => ({
    id: item.id,
    type: item.type,
    ...item.attributes,
    values: item.attributes.values.map(val => val.attributes.value),
    exist: true
  }));
};

export const mergeAttributesOnLabels = attrs =>
  attrs.reduce((arr, next) => {
    const existing = arr.find(attr => {
      return attr.attributes.label === next.attributes.label;
    });

    return !existing
      ? [...arr, next]
      : arr.map(attr => String(attr.id) !== String(existing.id)
        ? attr
        : {
            ...existing,
            attributes: {
              ...existing.attributes,
              values: [
                ...existing.attributes.values,
                ...next.attributes.values
              ]
            }
          }
      );
}, []);

export const getOwnSelectedEquipmentAttributes = createSelector(
  [getSelectedCategory, getSelectedEquipmentAttributes],
  (selected, attrs = []) =>
    attrs.filter(attr => selected.id === attr.attributes.category)
);

export const getOwnSelectedVariantAttributes = createSelector(
  [getSelectedCategory, getSelectedVariantAttributes],
  (selected, attrs = []) => 
    attrs.filter(attr => String(selected.id) === String(attr.attributes.category))
);

export const getInheritedSelectedEquipmentAttributes = createSelector(
  [getSelectedCategory, getSelectedEquipmentAttributes],
  (selected, attrs = []) =>
    mergeAttributesOnLabels(
      attrs.filter(attr => String(selected.id) !== String(attr.attributes.category))
    )
);

export const getInheritedSelectedVariantAttributes = createSelector(
  [getSelectedCategory, getSelectedVariantAttributes],
  (selected, attrs = []) =>
    mergeAttributesOnLabels(
      attrs.filter(attr => String(selected.id) !== String(attr.attributes.category))
    )
);

export const getSelectedEquipmentAttributesFormValues = createSelector(
  [getOwnSelectedEquipmentAttributes],
  getAttributesFormValues
);

export const getSelectedVariantAttributesFormValues = createSelector(
  [getOwnSelectedVariantAttributes],
  getAttributesFormValues
);

export const createBreadcrumbString = (category, breadcrumbs) =>
  `${
    !breadcrumbs || !breadcrumbs.length
      ? ''
      : `${breadcrumbs.map(b => b.name).join(' / ')} / `
  }${category.name}`;

export const getCategoryBreadcrumbs = createCachedSelector(
  [
    getCategoriesWithFacets,
    getCategoryById,
    (state, categoryId, includeCurrent) => !!includeCurrent
  ],
  (categories, category, includeCurrent) => {
    if (!categories || !categories.length || !category) {
      return null;
    }

    let currentCategory = category;
    let arr = [];

    if (includeCurrent) {
      arr.push(currentCategory);
    }

    while (currentCategory.parentCategory) {
      var found = false;

      for (var i in categories) {
        if (String(categories[i].id) === String(currentCategory.parentCategory)) {
          currentCategory = categories[i];
          arr.push(currentCategory);
          found = true;

          break;
        }
      }

      if (!found) {
        break;
      }
    }

    return arr.reverse();
  }
)((state, id) => `breadcrumb-${id}`);

export const getCategoryMatches = createSelector(
  [getCategories, (state, comparedItems) => comparedItems],
  (categories, comparedItems) => {
    // TODO: use lodash/intersectionBy here!

    return categories.filter(category => {
      for (var i = 0; i < comparedItems.length; i++) {
        if (comparedItems[i].slug === category.slug) {
          return true;
        }
      }
      return false;
    });
  }
);
